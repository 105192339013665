import React from 'react';
import Chat from './components/Chat';

function App() {
    return (
        <div className="App">
            <Chat/>
        </div>
    );
}

export default App;
