import React, { useEffect } from "react";
import { config } from "../config";

interface IProps {
    threadId: string
}

interface Message {
    role: 'user' | 'assistant';
    content: string;
}


export const History = (props: IProps) => {
    const {threadId} = props;
    const [messages, setMessages] = React.useState<Message[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const loadMessages = async () => {
        try {
            const response = await fetch(config.API_URL + '/api/fetchChatHistory/' + threadId, {
                method: 'GET'
            });

            const data = await response.json();
            if (data.data.length > 0) {
                let dataformatted = data.data.map((x: any) => {
                    return {role: x.role, content: x.content[0]["text"].value}
                })
                setMessages(dataformatted);
            }
            setLoading(false);

        } catch (error) {
            console.error('Error loading messahes:', error);
        }

    }
    useEffect(() => {
        loadMessages();
    }, [])
    return (
        <div className="chat-container">

            {loading && <div className="dot-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>}

            <div className="messages">
                {messages.map((msg, index) => (
                    <div key={index}>{index > 0 && <div key={index} className={`message ${msg.role}`}>
                        <strong>{msg.role === 'user' ? 'You' : config.BOT_NAME}:</strong>
                        <div
                            dangerouslySetInnerHTML={{__html: msg.content.replaceAll("[html]", "<br/>").replaceAll("[/html]", "<br/>")}}></div>

                    </div>}</div>
                ))}
            </div>
        </div>
    )
}