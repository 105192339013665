import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import '../assets/css/chat.css';
import '../assets/css/style.css';

import { fetchEventSource } from '@microsoft/fetch-event-source';
import { config } from "../config";
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { History } from "./History";
import NoSleep from 'nosleep.js';

//import sendIcon from '../assets/images/send-icon.svg';
//import headphoneIcon from '../assets/images/headphone.svg';
// import thumbsUpIcon from '../assets/images/thumbs_up_icon.svg';
// import tickIcon from '../assets/images/tick.svg';
// import copyIcon from '../assets/images/copy-icon.svg';
import endimage from '../assets/images/endimage.png';

import videoPlaceholder from "../assets/images/circle-gradient.png";
import { Intro } from './Intro';

interface Message {
    role: 'user' | 'assistant';
    content: string;
}

class AudioManager {
    audioContext: AudioContext;
    currentSource: AudioBufferSourceNode | null;

    constructor() {
        this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        this.currentSource = null;
    }

    async resumeContext() {
        if (this.audioContext.state === 'suspended') {
            await this.audioContext.resume();
        }
    }

    async decodeAudioData(arrayBuffer: ArrayBuffer): Promise<AudioBuffer> {
        await this.resumeContext();
        console.log('resumeContext complete');
        return this.audioContext.decodeAudioData(arrayBuffer)
            .then(audioBuffer => {
                console.log('decodeAudioData success');
                return audioBuffer;
            })
            .catch(error => {
                console.error('decodeAudioData error', error);
                throw error;
            });
    }

    createBufferSource(audioBuffer: AudioBuffer): AudioBufferSourceNode {
        const source = this.audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(this.audioContext.destination);
        return source;
    }

    stopAllSounds() {
        if (this.currentSource) {
            this.currentSource.stop();
            this.currentSource.disconnect();
            this.currentSource = null;
        }
    }
}

const Chat: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([{role: 'assistant', content: 'Hello!'}]);
    const noSleep = useRef<NoSleep | null>(null);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const audioContextRef = useRef<AudioContext | null>(null);
    const analyserRef = useRef<AnalyserNode | null>(null);
    const mediaStreamRef = useRef<MediaStream | null>(null);
    const animationFrameRef = useRef<number | null>(null);

    const [noSleepEnabled, setNoSleepEnabled] = useState<boolean>(false);
    const [started, setStarted] = useState<boolean>(false);
    const [permissionStatus, setPermissionStatus] = useState<PermissionState | null>(null);
    const [input, setInput] = useState<string>('');
    const [landingPage, setLandingPage] = useState<boolean>(true); //true
    const [audioMode, setAudioMode] = useState<boolean>(false);
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [thinking, setThinking] = useState<boolean>(false);
    const [playing, setPlaying] = useState<boolean>(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const audioModeRef = React.useRef<boolean>(false);
    const [computedText, setComputedText] = React.useState<any>([]);
    const [questionUid, setQuestionUid] = React.useState<string>('');
    const [soundDetected, setSoundDetected] = useState<boolean>(false);
    const [chatEnded, setchatEnded] = useState<boolean>(false); //false
    const [shareYes, setShareYes] = useState<boolean>(false);
    const [showsShareInterview, setShowsShareInterview] = useState<boolean>(false);
    const [cardHtml, setCardHtml] = useState<string>('');
    const [historyView, setHistoryView] = useState<boolean>(false);
    const [endpage, setEndPage] = useState<number>(1);

    const [assistant, setAssistant] = useState<any>(null);
    const [thread, setThread] = useState<any>(null);

    const isRecordingRef = React.useRef<boolean>(isRecording);
    const assistantRef = React.useRef<any>(assistant);
    const threadRef = React.useRef<any>(thread);
    const splitIndexRef = React.useRef<number>(0);
    const playingRef = React.useRef<boolean>(playing);
    const chatEndedRef = React.useRef<boolean>(chatEnded);
    const questionUidRef = React.useRef<string>(questionUid);
    const texttospeak = React.useRef<any>([]);
    const speakIndex = React.useRef<number>(0);
    const audioUrls = React.useRef<string[]>([]);
    const abortControllerRef = useRef<AbortController | null>(null);

    const audioManager = useRef(new AudioManager()).current;

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        finalTranscript,
        interimTranscript
    } = useSpeechRecognition();

    useEffect(() => {
        if (listening) {
            startMicrophone();
        } else {
            stopMicrophone();
        }
    }, [listening]);

    const startMicrophone = async () => {
        if (canvasRef.current) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({audio: true});
                mediaStreamRef.current = stream;

                audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
                const source = audioContextRef.current.createMediaStreamSource(stream);

                analyserRef.current = audioContextRef.current.createAnalyser();
                analyserRef.current.fftSize = 2048;

                source.connect(analyserRef.current);

                drawWaveform();
            } catch (err) {
                console.error('Error accessing the microphone', err);
            }
        }
    };

    const stopMicrophone = () => {
        if (mediaStreamRef.current) {
            mediaStreamRef.current.getTracks().forEach(track => track.stop());
            mediaStreamRef.current = null;
        }
        if (audioContextRef.current) {
            audioContextRef.current.close();
            audioContextRef.current = null;
        }
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
        }
    };

    const drawWaveform = () => {
        if (!canvasRef.current || !analyserRef.current) return;

        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext('2d');
        const analyser = analyserRef.current;

        if (!canvasCtx) return;

        analyser.fftSize = 256;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const draw = () => {
            analyser.getByteFrequencyData(dataArray);

            canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

            const groupWidth = 20; // Width of each group of bars
            const barWidth = 3; // Width of each bar
            const gap = 2; // Gap between bars in a group
            const groupGap = 4; // Gap between groups
            const numBarsPerGroup = 4; // Number of bars in each group

            let x = 0;
            for (let i = 0; i < bufferLength; i += numBarsPerGroup) {
                for (let j = 0; j < numBarsPerGroup; j++) {
                    const dataIndex = i + j;
                    if (dataIndex < bufferLength) {
                        const barHeight = (dataArray[dataIndex] / 255) * (canvas.height / 2) * 0.8;

                        canvasCtx.fillStyle = '#7ad9d3'; // Color for microphone input
                        const barX = x + j * (barWidth + gap);
                        canvasCtx.fillRect(barX, canvas.height / 2 - barHeight / 2, barWidth, barHeight);
                        canvasCtx.fillRect(barX, canvas.height / 2 + barHeight / 2, barWidth, -barHeight);
                    }
                }
                x += groupWidth + groupGap;
            }

            animationFrameRef.current = requestAnimationFrame(draw);
        };

        draw();
    };

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            alert('Browser does not support speech recognition.');
            return;
        }

        let timeoutId: any;

        const handleSpeechEnd = () => {
            setSoundDetected(false);
            SpeechRecognition.stopListening();
            setInput(transcript);
            sendMessage(transcript);
            resetTranscript();
        };
        if (finalTranscript) {
            handleSpeechEnd();
        }
        // if (listening) {
        //     clearTimeout(timeoutId);
        //     if (transcript) {
        //         timeoutId = setTimeout(handleSpeechEnd, 1000); // 3 seconds gap detection
        //     }
        // }

        // return () => clearTimeout(timeoutId);
    }, [transcript, finalTranscript, listening, browserSupportsSpeechRecognition, resetTranscript]);

    useEffect(() => {
        if (transcript) {
            setSoundDetected(true);
        }
    }, [transcript]);

    useEffect(() => {
        const queryParam = new URLSearchParams(window.location.search).get('threadId');
        if (queryParam) {
            setHistoryView(true);
            setThread(queryParam);
        } else {
            initChatBot();
        }
        checkMicrophonePermission()

        noSleep.current = new NoSleep();

    }, []);
    const enableNoSleep = () => {
        if (noSleep.current && !noSleepEnabled) {
            noSleep.current.enable();
            setNoSleepEnabled(true);
            console.log('NoSleep enabled');
        }
    }
    const startChat = () => {
        if (!started) {
            setStarted(true);
            sendMessage('Hello');
        }

    }
    const checkMicrophonePermission = async () => {
        try {
            const permission = await navigator.permissions.query({name: 'microphone' as PermissionName});
            setPermissionStatus(permission.state);
        } catch (err) {
            console.error('Error checking microphone permission', err);
            return null;
        }
    };

    const initChatBot = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const assistant = searchParams.get('assistant') || "asst_XWBbanOPE6QX0cm8rpz5u9uo"; // Default assistant ID

        try {
            const response = await fetch(config.API_URL + '/api/create-thread', {
                method: 'POST'
            });

            const data = await response.json();
            if (data.success) {
                setAssistant(assistant);
                setThread(data.threadId);
                return data.threadId;
            } else {
                throw new Error(data.error);
            }
        } catch (error) {
            console.error('Error creating thread:', error);
        }
    };

    const preloadAudioContext = () => {
        const silentAudioUrl = 'data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEAQB8AAEAfAAABAAgAZGF0YQAAAAA=';
        const silentAudio = new Audio(silentAudioUrl);

        silentAudio.play().then(() => {
            silentAudio.pause();
        }).catch((error) => {
            console.error('Silent audio playback failed:', error);
        });
    };

    const getInsideHtmlText = (inputText: string) => {
        const htmlTagPattern = /\[html\]([\s\S]*?)\[\/html\]/g;
        const matches = [];
        let match;

        while ((match = htmlTagPattern.exec(inputText)) !== null) {
            matches.push(match[1].trim());
        }

        if (matches.length > 0) {
            setchatEnded(true);
            setCardHtml(matches.join("<b/>"));
        }
    };
    const checkShareInterview = (inputText: string) => {
        // Convert the string to lowercase
        const lowercasedText = inputText.toLowerCase();

        // Check if "share this interview" exists in the lowercase string
        if (lowercasedText.includes("share this interview") || lowercasedText.includes("share interview")) {
            setShowsShareInterview(true);
        }
    }
    const checkEnChat = (inputText: string) => {
        // Convert the string to lowercase
        const lowercasedText = inputText.toLowerCase();

        // Check if "share this interview" exists in the lowercase string
        if (lowercasedText.includes("thank you for your time") || (lowercasedText.includes("share interview") && lowercasedText.includes("button"))) {
            setchatEnded(true);
        }
    }
    const removeHtmlTags = (inputText: string) => {
        const htmlTagPattern = /\[html\][\s\S]*?\[\/html\]/g;
        getInsideHtmlText(inputText);
        if (config.SAVE_LAST) {
            checkShareInterview(inputText);
        }
        checkEnChat(inputText);
        return inputText.replace(htmlTagPattern, '');
    };

    useEffect(() => {
        isRecordingRef.current = isRecording;
    }, [isRecording]);

    useEffect(() => {
        audioModeRef.current = audioMode;
    }, [audioMode]);

    useEffect(() => {
        chatEndedRef.current = chatEnded;
    }, [chatEnded]);

    useEffect(() => {
        assistantRef.current = assistant;
    }, [assistant]);

    useEffect(() => {
        questionUidRef.current = questionUid;
    }, [questionUid]);

    useEffect(() => {
        playingRef.current = playing;
    }, [playing]);

    useEffect(() => {
        threadRef.current = thread;
    }, [thread]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [messages]);
    const saveMessageToDrive = async (role: string, message: string) => {
        const response = await axios.post(
            config.API_URL + '/api/save-to-drive',
            {thread: threadRef.current, role, message},
        );
    }
    const saveAllMessageToDrive = async () => {
        const response = await axios.post(
            config.API_URL + '/api/save-all-to-drive',
            {thread: threadRef.current, messages},
        );
    }
    const sendMessage = async (messageContent?: string) => {
        setIsRecording(false);
        resetTranscript();
        setComputedText([]);
        setSoundDetected(false);
        splitIndexRef.current = 0;
        audioUrls.current = [];
        texttospeak.current = [];
        speakIndex.current = 0;

        setPlaying(false);
        const content = messageContent || input;
        if (!content || thinking) return;
        setInput('');
        setThinking(true);
        const userMessage: Message = {role: 'user', content};
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        try {
            const quid = uuidv4();
            setQuestionUid(quid);

            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            const ctrl = new AbortController();
            abortControllerRef.current = ctrl;
            if (!config.SAVE_LAST) {
                saveMessageToDrive("user", content);
            }

            fetchEventSource(config.API_URL + '/api/send-message', {
                openWhenHidden: true,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    thread: threadRef.current,
                    content,
                    assistant: assistantRef.current,
                    question_uid: quid,
                    audio_mode: audioModeRef.current
                }),
                signal: ctrl.signal,
                onmessage: (msg: any) => {
                    let data = JSON.parse(msg.data);
                    if (data.status === 'computing' && data.value) {
                        if (audioModeRef.current === false) {
                            setThinking(false);
                        } else {
                            let answerFormat = removeHtmlTags(data.value);
                            playAudio(answerFormat);
                        }

                        setComputedText((prevComputedText: any) => [...prevComputedText, data.value]);
                    } else if (data.status === 'completed' && data.value) {
                        if (!config.SAVE_LAST) {
                            saveMessageToDrive("assistant", data.value);
                        }

                        setComputedText((prevComputedText: any) => [...prevComputedText, prevComputedText[prevComputedText.length - 1].includes(".") ? '' : '.']);

                        setQuestionUid('');

                        setThinking(false);
                        const botMessage = {role: 'assistant', content: data.value};
                        setMessages((prevMessages: any) => [...prevMessages, botMessage]);
                    }
                },
                onerror: (error: any) => {
                    console.log("Failed ", error);
                    retrySpeech();
                }
            });
        } catch (error) {
            console.error('Error sending message:', error);
            retrySpeech();
        }
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    };

    const retrySpeech = () => {
        setThinking(false);
        setPlaying(false);
        listenNextSpeech();
        audioUrls.current = [];
    };
    const playNext = async () => {
        try {
            if (audioManager.audioContext.state === 'suspended') {
                await audioManager.audioContext.resume();
                console.log('AudioContext resumed');
            }
            console.log('speakIndex.current', speakIndex.current);
            console.log('texttospeak.current.length', texttospeak.current.length);
            if (speakIndex.current < texttospeak.current.length) {
                let nextAudio = texttospeak.current[speakIndex.current];
                console.log('nextAudio', nextAudio);

                const arrayBuffer = await fetch(nextAudio.audioUrl!).then(res => {
                    if (!res.ok) {
                        throw new Error('Failed to fetch audio data');
                    }
                    return res.arrayBuffer();
                });
                console.log('arrayBuffer', arrayBuffer);

                const audioBuffer = await audioManager.decodeAudioData(arrayBuffer);
                console.log('audioBuffer', audioBuffer);

                // Stop any previously playing audio
                if (audioManager.currentSource) {
                    console.log('Stopping previous audio');
                    audioManager.stopAllSounds();
                }

                const source = audioManager.createBufferSource(audioBuffer);
                audioManager.currentSource = source;

                // Create an AnalyserNode for the waveform visualization
                const analyser = audioManager.audioContext.createAnalyser();
                analyser.fftSize = 256;
                const bufferLength = analyser.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);

                source.connect(analyser);
                analyser.connect(audioManager.audioContext.destination);

                // Use the same canvas for drawing the waveform
                const canvas = canvasRef.current;
                const canvasCtx = canvas?.getContext('2d');

                const drawWaveform = () => {
                    if (!canvasCtx || !canvas) return;

                    analyser.getByteFrequencyData(dataArray);

                    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

                    const groupWidth = 20; // Width of each group of bars
                    const barWidth = 3; // Width of each bar
                    const gap = 2; // Gap between bars in a group
                    const groupGap = 4; // Gap between groups
                    const numBarsPerGroup = 4; // Number of bars in each group

                    let x = 0;
                    for (let i = 0; i < bufferLength; i += numBarsPerGroup) {
                        for (let j = 0; j < numBarsPerGroup; j++) {
                            const dataIndex = i + j;
                            if (dataIndex < bufferLength) {
                                const barHeight = (dataArray[dataIndex] / 255) * (canvas.height / 2) * 0.8;

                                canvasCtx.fillStyle = '#479DFF'; // Color for audio playback
                                const barX = x + j * (barWidth + gap);
                                canvasCtx.fillRect(barX, canvas.height / 2 - barHeight / 2, barWidth, barHeight);
                                canvasCtx.fillRect(barX, canvas.height / 2 + barHeight / 2, barWidth, -barHeight);
                            }
                        }
                        x += groupWidth + groupGap;
                    }

                    requestAnimationFrame(drawWaveform);
                };

                drawWaveform();

                source.onended = () => {
                    console.log('Audio ended');
                    // Revoke the blob URL after use
                    URL.revokeObjectURL(nextAudio.audioUrl!);
                    audioOnended();
                    audioManager.currentSource = null; // Clear the current source
                };

                // Start the audio
                source.start(0);
                console.log('source start');

                texttospeak.current[speakIndex.current].played = true;
                speakIndex.current++;
            }
        } catch (error) {
            console.error('Audio playback failed:', error);
            setTimeout(() => {
                audioOnended();
            }, 1000);
        }
    };

    const requestMicrophoneAccess = async () => {
        try {
            moveToPage('audio')
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            console.log('Microphone access granted');
            // You can use the stream here, for example, to record audio
        } catch (err) {
            console.error('Microphone access denied', err);
        }
    };

    const audioOnended = async () => {
        console.log("audioOnended ", questionUidRef.current, " speakIndex.current ", speakIndex.current, " texttospeak.current.length ", texttospeak.current.length)
        if (questionUidRef.current === '' && speakIndex.current === (texttospeak.current.length)) {
            setThinking(false);
            setPlaying(false);
            listenNextSpeech();
            audioUrls.current = [];
        } else if (texttospeak.current.length > speakIndex.current) {
            let nextAudio = texttospeak.current[speakIndex.current];
            if (nextAudio.played === true) {
                speakIndex.current++;
                audioOnended();
            } else {
                if (nextAudio.audioUrl === null) {
                    setTimeout(() => {
                        audioOnended();
                    }, 1000);
                } else {
                    await playNext();
                }
            }
        } else {
            setThinking(false);
            setPlaying(false);
            listenNextSpeech();
            audioUrls.current = [];
        }
    };

    const playAudio = async (text: string, retry: boolean = false) => {
        try {
            // Trim spaces and new lines
            const trimmedText = text.trim().replace(/\s+/g, ' ');

            if (!retry) {
                texttospeak.current.push({text: trimmedText, status: 'inprogress', audioUrl: null, played: false});
            }
            console.log("play audio", trimmedText);

            const response = await axios.post(
                config.API_URL + '/api/text-to-speech-openai',
                {text: trimmedText},
                {responseType: 'arraybuffer'}
            );

            const findItemIndex = texttospeak.current.findIndex((x: any) => x.text === trimmedText);
            console.log('findItemIndex', findItemIndex);
            if (findItemIndex > -1) {
                const blob = new Blob([response.data], {type: 'audio/mpeg'});
                const blobUrl = URL.createObjectURL(blob);
                console.log('blobUrl', blobUrl);
                texttospeak.current[findItemIndex].status = 'completed';
                texttospeak.current[findItemIndex].audioUrl = blobUrl;
            }

            if (playingRef.current === false && speakIndex.current === 0 && findItemIndex === 0) {
                setThinking(false);
                setPlaying(true);
                console.log('playNext');
                await playNext();
            }
        } catch (error) {
            console.error('An error occurred while processing the request:', error);
            playAudio(text, true);
        }
    };

    const listenNextSpeech = () => {
        try {
            if (chatEndedRef.current === false && audioModeRef.current && !isRecordingRef.current) {
                console.log("Starting speech recognition...");

                // Check if the audio context is suspended and resume it
                if (audioManager.audioContext.state === 'suspended') {
                    audioManager.audioContext.resume().then(() => {
                        console.log('AudioContext resumed for speech recognition');
                        SpeechRecognition.startListening({continuous: true});
                        setIsRecording(true);
                    }).catch(error => {
                        console.error('Failed to resume AudioContext:', error);
                    });
                } else {
                    SpeechRecognition.startListening({continuous: true});
                    setIsRecording(true);
                }
            }
        } catch (e) {
            console.log("Listen error: ", e);

            // Add a delay before retrying to prevent rapid recursive calls
            setTimeout(() => {
                listenNextSpeech();
            }, 1000);
        }
    };


    const handleVoiceInput = async () => {
        await audioManager.resumeContext(); // Resume audio context here
        if (audioMode) {
            SpeechRecognition.stopListening();
            setIsRecording(false);
        } else {
            if (messages.length === 1) {
                // setTimeout(() => {
                //     sendMessage('Hello');
                // }, 1000);
            } else {
                SpeechRecognition.startListening({continuous: true});
                setIsRecording(true);
            }
        }
        setAudioMode(!audioMode);
    };

    const moveToPage = async (mode: string) => {
        enableNoSleep();
        await audioManager.resumeContext(); // Resume audio context here
        if (mode === 'audio') {
            preloadAudioContext();
            handleVoiceInput();
        } else {
            setTimeout(() => {
                sendMessage('Hello');
            }, 1000);
        }
        setLandingPage(false);
    };
    const handleShare = () => {
        saveAllMessageToDrive();
        setShareYes(true);
        setEndPage(2);
        setTimeout(() => {
            setShareYes(false);
        }, 3000)

    }


    return (
        <>
            {thread === null && <div className="dot-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>}
            {(thread && historyView) && <History threadId={thread}/>}
            {(historyView === false && landingPage && thread) &&
                <Intro requestMicrophoneAccess={requestMicrophoneAccess} permissionStatus={permissionStatus}
                       moveToPage={moveToPage}/>
            }
            {(chatEnded && landingPage === false) && <div className="landing-container">
                <div className="homepage-wrapper">
                    <div className="homepage-container">
                        <div className={"homepage-inner-container page" + endpage}>
                            <div className="homepage-welcome homepage-microphone-permission page-item end-page">
                                <div className="homepage-img">
                                    <img src={endimage} alt=""/>
                                </div>
                                {cardHtml &&
                                    <div className='card-data' dangerouslySetInnerHTML={{__html: cardHtml}}></div>}
                                {!cardHtml &&
                                    <div className="homepage-title">Thank you for joining the Art of Meaning</div>}
                                <div className="homepage-desc">
                                    Please consider sharing this conversation to help my research
                                </div>

                                <div className="homepage-btn-wrapper">
                                    <button className="cancel-btn" onClick={() => handleShare()}>Share anonymously
                                    </button>
                                    {/* <button className="cancel-btn" onClick={() => setEndPage(2)}>No Thanks</button> */}
                                </div>
                            </div>
                            <div className="homepage-welcome homepage-microphone-permission page-item">
                                <div className="homepage-img">
                                    <img src={endimage} alt=""/>
                                </div>
                                {cardHtml &&
                                    <div className='card-data' dangerouslySetInnerHTML={{__html: cardHtml}}></div>}
                                {!cardHtml &&
                                    <div className="homepage-title">Thank you for joining the Art of Meaning</div>}
                                {/* <div className="homepage-title">{shareYes ? "Thanks for sharing!" : "Good luck!"}</div> */}
                                <div className="homepage-desc">
                                    {cardHtml && "Thank you!"}
                                    {/* Question? Comments?
                                    <br />
                                    <a href='mailto:hello@doctoryaguri.com'>hello@doctoryaguri.com</a> */}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {chatEnded === false && historyView === false && landingPage === false && <div className="chat-container">

                <div className="taptobegin-wrapper" onClick={() => started === false && startChat()}>
                    <div className="taptobegin-container">
                        {/* {chatEnded &&
                            <div className='end-container'>
                                <div className='end-icon'><img src={thumbsUpIcon}/></div>
                                <div className='end-text'>THANK YOU</div>
                                {cardHtml && <div className='card-data' dangerouslySetInnerHTML={{__html: cardHtml}}></div>}
                            </div>
                        } */}
                        {/* {chatEnded && <div className="end-container">
                                <button onClick={handleShare} className="copy-button"><img src={tickIcon} width={20} />Share This Interview</button>
                        </div>} */}
                        <div className="middle-gradient gradient-animation">
                            <video
                                playsInline
                                autoPlay
                                muted
                                loop
                                className="background-video"
                                poster={videoPlaceholder}
                            >
                                <source src="/AI_Motion.mp4" type="video/mp4"/>
                                {/* Fallback image for older browsers */}
                                <img src={videoPlaceholder} alt="Fallback Image" className="fallback-image"/>
                            </video>
                        </div>


                        {/*{started ? (<div
                            className="bottom-speaking-text-area">{listening ? transcript === '' ? 'Listening...' : 'Listening...' : ''}</div>)
                            :
                            <></>
                        }*/}
                        {!started &&
                            <div
                                className="bottom-speaking-text-area">Tap anywhere to begin</div>
                        }
                        <div className="audio-wave-wrapper">
                            <canvas ref={canvasRef} width="320" height="80"/>
                        </div>
                    </div>
                </div>


            </div>
            }
            <ToastContainer/>
        </>
    );
};

export default Chat;
